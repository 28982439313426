import * as React from 'react';

const Trash = () => (
  <svg width="1em" height="1em" viewBox="0 0 15 20">
    <defs>
      <path id="prefix__a" d="M0 0h14.991v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.5 7a.49.49 0 00-.5.478v9.044a.49.49 0 00.5.478.49.49 0 00.5-.478V7.478A.49.49 0 0010.5 7M6.5 7a.49.49 0 00-.5.478v9.044a.49.49 0 00.5.478.49.49 0 00.5-.478V7.478A.49.49 0 006.5 7"
        fill="#FFF"
      />
      <g>
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M1.651 3.372h11.69c.429 0 .777.378.777.843 0 .466-.348.844-.778.844H1.651c-.43 0-.778-.378-.778-.844 0-.465.349-.843.778-.843zM4.73 1.85a.942.942 0 01.246-.65.8.8 0 01.602-.263h3.838a.8.8 0 01.602.262c.16.172.248.407.245.651v.586H4.73V1.85zm6.856 17.213H3.406c-.739 0-1.314-.688-1.314-1.569V5.995H12.9v11.5c0 .88-.575 1.568-1.314 1.568zM1.228 5.954v11.54c0 .682.23 1.323.634 1.782.401.461.96.723 1.544.724h8.18a2.054 2.054 0 001.544-.724c.403-.46.634-1.1.634-1.782V5.954c.801-.23 1.32-1.07 1.214-1.96-.108-.892-.808-1.558-1.638-1.558h-2.213V1.85a1.922 1.922 0 00-.499-1.313A1.634 1.634 0 009.415 0H5.577a1.634 1.634 0 00-1.214.537c-.321.348-.5.82-.498 1.313v.586H1.65c-.829 0-1.53.666-1.637 1.557-.107.892.412 1.73 1.214 1.961z"
          fill="#FFF"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        d="M8.5 7a.49.49 0 00-.5.478v9.044a.49.49 0 00.5.478.49.49 0 00.5-.478V7.478A.49.49 0 008.5 7"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Trash;
