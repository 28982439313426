import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';

import Cart from 'components/cart';

import { getCenterName } from 'utils/location';

import useSetCenterCookie from 'hooks/use-set-center-cookie';

const Produit = (props) => {
  const [centerId, setCenterId] = useState('');
  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, []);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <Cart centerId={centerId} path="/panier" />
    </Router>
  );
};

export default Produit;
