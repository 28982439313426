import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Checkbox, Divider } from 'antd';
import Cookies from 'universal-cookie';
import qs from 'qs';
import API from 'api';
import { centerCookieIds } from 'utils/constants';
import userIsLoggedIn from 'utils/checkLoggedIn';

import CaliceoButton from 'components/buttons/CaliceoButton';
import PinIcon from 'components/icon-components/Pin';
import CartProduct from './CartProduct';
import TrashIcon from 'components/icon-components/Trash';

import { notification } from 'antd';
import load_gif from '../../images/load.gif';

const cookies = new Cookies();

const NonEmptyCart = (props) => {
  const {
    selectedCenter,
    cartData,
    removeItemFromCart,
    changeQuantity,
    addItemToCart,
    upgradeItemInCart,
    setCart,
    setProductAsGift,
    removeProductAsGift,
    giftProducts,
    changeGiftStatus,
    removeProductAsCoffret,
    coffretProducts,
    setCodeCadeau,
    removeCodeCadeau,
    codesCadeau,
  } = props;
  
  
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [loaderPromo, setLoaderPromo] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const commitCart = () => {
    giftProducts.map((gift) => {
      removeProductAsGift(gift)
    });
    cartData.Products.map((product) => {
      if(product.gift) {
        setProductAsGift(product);
      }
    });

    if (!agreementChecked) {
      setShowCheckboxError(true);
    } else {
      navigate('/checkout');
    }
  };

  const toggleAgreementCheckbox = () => {
    setAgreementChecked((prevChecked) => !prevChecked);
    setShowCheckboxError(false);
  };

  const changePromoCodeValue = (e) => {
    setPromoCodeValue(e.target.value);
  };

  const submitPromoCode = () => {
    setLoaderPromo(true);
    if(userIsLoggedIn(selectedCenter?.centerId)) {
      const postValues = {
        pcode: promoCodeValue,
        session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
        aid: 5,
      };
      const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
      API.get(`/fudpefrspnph?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
        //console.log(res.data.status);
        if (res.data.status === 1) {
          //console.log(res.data.responseData.cartContents.codepromo.remise);
          if(res.data.responseData?.cartContents?.codepromo?.remise !== 0) {
            setPromoCodeError('');
            setPromoCodeValue('');
            setCodeCadeau(promoCodeValue);
            setCart(res.data.responseData.cartContents);
            localStorage.setItem('CC', promoCodeValue);
            notification.success({
              message: "Votre code promo a bien été enregistré.",
            });
          }else {
            setPromoCodeError('');
            setPromoCodeValue('');
            setCodeCadeau(promoCodeValue);
            setCart(res.data.responseData.cartContents);
            localStorage.setItem('CC', promoCodeValue);
            notification.error({
              message: "Ce code promotionnel n'est pas valide pour les offres de votre panier",
            });
          }
          
        }
        if (res.data.status === 0) {
          setPromoCodeError('Error');
          setPromoCodeValue('');
        }
        if (res.data.status === 3) {
          setPromoCodeError('Error');
          setPromoCodeValue('');
          notification.error({
            message: "Votre code promo n'est pas valide.",
          });

        }
        setLoaderPromo(false);
      });
    }else {
      notification.error({
        message: "Vous devez être connecté pour valider votre code promo.",
      });
      setLoaderPromo(false);
    }


    
  };

  const removeCodeCadeauReq = (code) => {
    const postValues = {
      pcode: code,
      session: cookies.get(centerCookieIds[selectedCenter?.centerId].cookieName),
      aid: 1,
    };
    const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
    API.get(`/fudpefrspnph?${qstring}`, { centerId: selectedCenter?.centerId }).then((res) => {
      //console.log(res.data.status);
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
        localStorage.setItem('CC', '');
        notification.success({
          message: "Votre code promo a bien été retiré de votre panier",
        });
      }
    });
  }

  return (
    <div className="cart--wrapper">
      <div className="cart-center-header-name">
        <PinIcon fill="#414141" />
        {selectedCenter?.name}
      </div>
      <h2 className="cart--header">Mon panier</h2>
      <div className="cart-body--wrapper">
        <div className="cart-body--items">
          {cartData.Products.map((product) => (
            <CartProduct
              product={product}
              changeQuantity={changeQuantity}
              key={product.uid}
              centerId={selectedCenter?.centerId}
              removeItemFromCart={removeItemFromCart}
              addItemToCart={addItemToCart}
              upgradeItemInCart={upgradeItemInCart}
              productIds={cartData.Products.map((product) => product.id)}
              setProductAsGift={setProductAsGift}
              removeProductAsGift={removeProductAsGift}
              giftProducts={giftProducts}
              removeProductAsCoffret={removeProductAsCoffret}
              coffretProducts={coffretProducts}
              changeGiftStatus={changeGiftStatus}
            />
          ))}
        </div>
        <div className="cart-body--actions">
          <div className="cart-body--promo-section">
            <p className="promo-section-header">CODE PROMO</p>
            {cartData?.codepromo?.remise != 0 ? (
              <>
                <p className="checkout-section-price">
                  Montant de la remise
                  <span>
                    - {cartData.codepromo.remise.toFixed(2)}
                    €
                  </span>
                </p>
                <div className="liste_code_promo">
                {cartData?.codepromo?.codes?.map((c, i) => {
                  return (
                    <div>
                    <p>{c}</p>
                    <button
                      className="cart-product-remove--button"
                      onClick={() => removeCodeCadeauReq(c)}
                      type="button"
                    >
                      <TrashIcon />
                    </button>
                    </div>
                  );
                })}
                </div>
              </>
            ) : (
              <>
                <p className="promo-section-description">
                Saisissez le code promo pour profiter de votre avantage
              </p>
              <div className="promo-section-input--wrapper">
                {loaderPromo ? (
                  <>
                    <div className={"loader loaderCode"}>
                      <img src={load_gif} style={{ width: 50 }} />
                    </div>
                  </>
                ) : null}
                <input className="promo-section-input" value={promoCodeValue} onChange={changePromoCodeValue} type="text" />
                <CaliceoButton
                  title=""
                  primaryButton
                  onClick={submitPromoCode}
                  classNames="confirm-promo--btn"
                  showIcon
                />
              </div>
              </>
            )}
            
          </div>
          <div className="cart-body--checkout-section">
            <p className="checkout-section-article-number">{`(${cartData.Products.length} article${cartData.Products.length > 1 ? 's' : ''})`}</p>
            
            {cartData?.chequecadeau?.remise != 0 ? (
              <>
                <p className="checkout-section-price">
                  Chèque cadeau
                  <span>
                    - {cartData.chequecadeau.remise.toFixed(2)}
                    €
                  </span>
                </p>
              </>
            ) : null}
            <p className="checkout-section-price">
              Total TTC
              <span>
                {cartData.totalTTC.toFixed(2)}
                €
              </span>
            </p>
            <p className="checkout-section-tax">
              Dont TVA
              <span>
                {cartData.totalTVA.toFixed(2)}
                €
              </span>
            </p>
            <Divider className="checkout-section-divider" />
            <Checkbox
              className="cart-checkbox cart-consent-checkbox"
              checked={agreementChecked}
              onClick={toggleAgreementCheckbox}
            >
              Je reconnais avoir lu et accepte les conditions
              générales de vente du centre Calicéo de
              {' '}
              {selectedCenter?.name}
            </Checkbox>
            {showCheckboxError && (
              <p className="terms-error">
                Veuillez accepter les termes et conditions
              </p>
            )}
            <CaliceoButton
              title="VALIDER MON PANIER"
              primaryButton
              onClick={commitCart}
              classNames="promo-offer--btn"
              showIcon={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonEmptyCart;
