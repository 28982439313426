import React, { useState, useCallback, useEffect } from 'react';
// import RightArrow from '../icon-components/RightArrow';
import InfoIcon from 'images/icons/info-icon.svg';
import { Popover } from 'antd';
import './cartAddSelling.scss';
// import qs from 'qs';
// const [setOrderLoading] = useState(false);
// import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';

// import API from 'api';
import CaliceoButton from '../buttons/CaliceoButton';

const CartAddSelling = (props) => {
  const {
    product, addItemToCart,
  } = props;

  const [orderLoading, setOrderLoading] = useState(false);

  const addSelling = async () => {
    setOrderLoading(true);
    await addItemToCart(product.id, 1);
    setOrderLoading(false);
  };

  const bgImgStyle = {
    background: 'center / cover no-repeat url(' + product.accrocheImage + ')',
  };
  const tooltip = <div className="cart-product--add-selling--info--title--more-info--tooltip" dangerouslySetInnerHTML={{__html: product.texte_tooltip }}></div>;

  return (
        <div className="cart-product--add-selling--cont">
          <div className="cart-product--add-selling--in">
            <div className="cart-product--add-selling--image--cont">
              <div className="cart-product--add-selling--image--in" style={bgImgStyle}>
              </div>
            </div>
            <div className="cart-product--add-selling--info--cont">
              <div className="cart-product--add-selling--info--in">
                <div className="cart-product--add-selling--info--title--cont">
                  <div className="cart-product--add-selling--info--title--in">
                    <p className="cart-product--add-selling--info--title--text">{product.titre} | {product.montantAdditionel} € &nbsp;
                      <Popover content={tooltip} placement="right" trigger="hover">
                        <img className="cart-product--add-selling--info--title--more-info" src={InfoIcon} alt="More info" />
                      </Popover>
                    </p>
                  </div>
                </div>
                <div className="cart-product--add-selling--info--desc--cont">
                  <div className="cart-product--add-selling--info--desc--in">
                    <p className="cart-product--add-selling--info--desc--text"
                       dangerouslySetInnerHTML={{__html: product.texte }}></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-product--add-selling--button--cont">
              <div className="cart-product--add-selling--button--in">
                <CaliceoButton
                  primaryButton
                  title="Bonne idée !"
                  classNames="cart-product--add-selling--button--btn"
                  onClick={addSelling}
                  loading={orderLoading}
                  disabled={orderLoading}
                />
              </div>
            </div>
          </div>
        </div>
  );
};

export default CartAddSelling;
