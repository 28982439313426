
import RightArrow from '../icon-components/RightArrow';
import InfoIcon from 'images/icons/info-icon.svg';
import { Checkbox, Popover } from 'antd';
import './cartUpSelling.scss';
import React, { useState } from 'react';
import CaliceoButton from '../buttons/CaliceoButton';

const CartUpSelling = (props) => {
  const {
    currentProduct, newProduct, upgradeItemInCart,
  } = props;

  const [orderLoading, setOrderLoading] = useState(false);

  const tooltip = <div className="cart-product--up-selling--info--more-info--tooltip" dangerouslySetInnerHTML={{__html: newProduct.texte_tooltip }}></div>;


  const upSelling = async () => {
    setOrderLoading(true);
    await upgradeItemInCart(currentProduct, newProduct);
    setOrderLoading(false);
  };

  return (
    <div className="cart-product--up-selling--cont">
      <div className="cart-product--up-selling--in">
        <div className="cart-product--up-selling--info--cont">
          <div className="cart-product--up-selling--info--in">
            <div className="cart-product--up-selling--info--title--cont">
              <div className="cart-product--up-selling--info--title--in">
                <p className="cart-product--up-selling--info--title--text"
                   dangerouslySetInnerHTML={{__html: newProduct.texte }}></p>
              </div>
            </div>
            <div className="cart-product--up-selling--info--more-info--cont">
              <div className="cart-product--up-selling--info--more-info--in">
                <Popover content={tooltip} placement="right" trigger="hover">
                  <img className="cart-product--up-selling--info--more-info--icon" src={InfoIcon} alt="More info" />
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className="cart-product--up-selling--button--cont">
          <div className="cart-product--up-selling--button--in">
            <CaliceoButton
              primaryButton
              title="Bonne idée !"
              classNames="cart-product--up-selling--button--btn"
              onClick={upSelling}
              loading={orderLoading}
              disabled={orderLoading}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default CartUpSelling;
