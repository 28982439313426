import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectCenters } from 'redux/selectors/centers';
import { selectCartData, selectGiftProducts, selectCoffretProducts, selectCodes } from 'redux/selectors/cart';
import {
  setCart,
  setProductAsGift,
  removeProductAsGift,
  removeProductAsCoffret,
  setCodeCadeau,
  removeCodeCadeau,
} from 'redux/actions/cart';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
  cartData: selectCartData(state),
  giftProducts: selectGiftProducts(state),
  coffretProducts: selectCoffretProducts(state),
  codesCadeau:selectCodes(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setCart,
    setProductAsGift,
    removeProductAsGift,
    removeProductAsCoffret,
    setCodeCadeau,
    removeCodeCadeau,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
