import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

import TrashIcon from 'components/icon-components/Trash';
import timeConvert from 'utils/time';
import { fourthImage } from 'utils/dummyImages';
import CartAddSelling from './CartAddSelling';
import CartUpSelling from './CartUpSelling';

import GiftIcon from 'components/icon-components/Cadeau';

const CartProduct = (props) => {
  const {
    product,
    centerId,
    removeItemFromCart,
    changeQuantity,
    addItemToCart,
    upgradeItemInCart,
    productIds,
    setProductAsGift,
    removeProductAsGift,
    giftProducts,
    changeGiftStatus,
    removeProductAsCoffret,
    coffretProducts,
  } = props;
  const [quantityDisplayed, setQuantityDisplayed] = useState(product.quantity);
  const [productIsGift, setProductIsGift] = useState(product.gift);
  // const [orderLoading, setOrderLoading] = useState(false);
  

  const incrementQuantity = () => {
    setQuantityDisplayed((prevQuantityDisplayed) => prevQuantityDisplayed + 1);
  };

  const decrementQuantity = () => {
    if (quantityDisplayed > 1) {
      setQuantityDisplayed((prevQuantityDisplayed) => prevQuantityDisplayed - 1);
    }
  };

  const changeProductIsGift = (e) => {
    setProductIsGift(e.target.checked);
    //changeGiftStatus(product.id, product.quantity, null, e.target.checked);
  };

  useEffect(() => {
    if (productIsGift) {
      setProductAsGift(product);
      changeQuantity(product.uid, 1);
      setQuantityDisplayed(1);
    } else {
      removeProductAsGift(product);
    }
  }, [productIsGift]);

  useEffect(() => {
    changeQuantity(product.uid, quantityDisplayed);
  }, [quantityDisplayed]);

  //console.log(product)

  return (
    <div className="cart-product--wrapper">
      <div className="cart-product">
        <button
          className="cart-product-remove--button"
          onClick={() => {
            removeProductAsGift(product);
            removeItemFromCart(product)
          }}
          type="button"
        >
          <TrashIcon />
        </button>
        <div className="cart-product--body">
          <div className="cart-product--body-image--wrapper">
            <img className="cart-product--body-image" src={product.teaserImageUrl || fourthImage} alt={product.title} />
          </div>
          <div className="cart-product--info">
            <p className="cart-product--title">{product.title}</p>
            {(product?.reservation?.date != null ) ? (
              <p className="gift_icon_cart"><span>Planifié le {product?.reservation?.date} à {product?.reservation.hour}</span></p>
            ) : null}
            {(productIsGift && !product.envp) ? (
              <p className="gift_icon_cart"><GiftIcon fill={"#8bacdb"} /> <span>Ce produit est un cadeau </span></p>
            ) : null}
            {(productIsGift && product.envp) ? (
              <>
                <p className="gift_icon_cart"><GiftIcon fill={"#8bacdb"} /> <span>Ce produit est un cadeau avec l'option enveloppe cadeau<br />Adresse d'envoi : {product.address}</span></p>
              </>
            ) : null}

            {/* {(productIsGift && product.mainpropre) ? (
              <p className="gift_icon_cart"><span>Option remise en main propre</span></p>
            ) : null} */}
            
          </div>
        </div>
        <div className="ml-auto cart-mobile-margin">

          {/* {product?.bid === 's' && ( */}
            <div className="cart-product-quantity--wrapper">
              {(!productIsGift) && (product?.reservation?.date == null) ? (
                <button type="button" onClick={decrementQuantity} className="quantity-button">
                  &#8722;
                </button>
              ) : null}
              
              <span className="cart-product-quantity">{quantityDisplayed}</span>
              {(!productIsGift) && (product?.reservation?.date == null) ? (
                <button type="button" onClick={incrementQuantity} className="quantity-button">
                  &#43;
                </button>
              ) : null}
              
            </div>
          {/* )} */}
          <p className="cart-product-price">{`${quantityDisplayed * product.price}€`}</p>
        </div>
      </div>
      {product.addSelling.length > 0 && productIds.indexOf(parseInt(product.addSelling[0]?.id)) === -1 && (
      // {product.addSelling.length > 0 && (
        <CartAddSelling
          product={product.addSelling[0]}
          addItemToCart={addItemToCart}
        />
      )}
      {product.upSelling.length > 0 && productIds.indexOf(parseInt(product.upSelling[0]?.id)) === -1 && (
        <CartUpSelling
          currentProduct={product}
          newProduct={product.upSelling[0]}
          upgradeItemInCart={upgradeItemInCart}
        />
      )}
    </div>
  );
};

export default CartProduct;
