import React from 'react';
import { Divider } from 'antd';
import { Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import EMPTY_CART_LINKS_QUERY from 'hooks/use-empty-cart-links';
import PinIcon from 'components/icon-components/Pin';
import CaliceoLink from 'components/buttons/CaliceoLink';

const EmptyCart = (props) => {
  const { selectedCenter } = props;

  const { data } = useQuery(EMPTY_CART_LINKS_QUERY, {
    variables: {
      domainAccess: selectedCenter?.domainAccess,
    },
  });

  return (
    <div className="cart--wrapper">
      <div className="cart-center-header-name">
        <PinIcon fill="#414141" />
        {selectedCenter?.name}
      </div>
      <h2 className="cart--header">Votre panier est vide :(</h2>
      <CaliceoLink
        url="/offers"
        title="Découvrir toutes nos offres"
        primaryButton
      />
      {data && (
        <div className="cart--discover-section">
          <Divider plain className="consumables--divider">
            {data?.blockContentQuery?.entities[0]?.fieldTitre}
          </Divider>
          <div className="cart--discover-cards-wrapper">
            {data?.blockContentQuery?.entities[0]?.fieldUniversAccroches?.map(({ entity }) => (
              <Link to={entity.fieldAccrocheLien.url.path} className="cart--discover-card" key={`${entity.fieldAccrocheLien.url.path}`}>
                <img className="cart-discover-card--image" src={entity.fieldAccrocheImage.entity.fieldMediaImage.url} alt={entity.fieldAccrocheLien.title} />
                <h3 className="cart--discover-card-title">{entity.fieldAccrocheLien.title}</h3>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyCart;
