import gql from 'graphql-tag';

const EMPTY_CART_LINKS_QUERY = gql`
  query EmptyCartLinksQuery($domainAccess: [String]){
    blockContentQuery(
      filter: {
        conditions: [
          {
            field: "field_relation_domain"
            value: $domainAccess
          }
          { field: "type", value: "univers" }
        ]
      }
    ) {
      entities {
        entityId
        ... on BlockContentUnivers {
          fieldTitre
          fieldUniversAccroches {
            entity {
              ... on ParagraphUniversAccroche {
                fieldAccrocheLien {
                  title
                  url {
                    path
                  }
                  uri
                }
                fieldAccrocheIcone {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        url
                      }
                    }
                  }
                }
                fieldAccrocheImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default EMPTY_CART_LINKS_QUERY;
