import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';

import API from 'api';
import { centerCookieIds } from 'utils/constants';
import Cookies from 'universal-cookie';
import qs from 'qs';
import { getCenterById } from 'utils/getCentersBy';
import SelectYourCenter from 'components/select-center/SelectYourCenter';
import EmptyCart from './EmptyCart';
import NonEmptyCart from './NonEmptyCart';
import {notification } from 'antd';

import './cart.scss';

const Cart = (props) => {
  const {
    centers,
    location,
    actions: {
      setCart,
      setProductAsGift,
      removeProductAsGift,
      removeProductAsCoffret,
      setCodeCadeau,
      removeCodeCadeau,
    },
    cartData,
    centerId,
    giftProducts,
    coffretProducts,
    codesCadeau,
  } = props;

  //console.log(cartData);

  const isValidCenter = centers.some((_center) => _center.centerId.includes(centerId));

  const cookies = new Cookies();

  const [selectedCenter, setSelectedCenter] = useState(null);
  const [sameProducts, setSameProducts] = useState([]);

  useEffect(() => {
    if (isValidCenter) {
      API.get(`/uhfudbs?session=${cookies.get(centerCookieIds[centerId].cookieName)}`).then((res) => {
        if (res.data.status === 1) {
          console.log(res.data.responseData.cartContents);
          setCart(res.data.responseData.cartContents);
        }else {
          if(res.data.status > 1) {
            if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
              notification.error({
                message: res.data.errorMessage,
              });
            }
          }
        }
      });
      setSelectedCenter(getCenterById(centers, centerId));
    }
  }, [centers]);

  if (!cartData) {
    return null;
  }

  const changeQuantity = (uid, quantity) => {
    const values = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      uid,
      quantity,
    };
    API.post('/odibohfqspevdurvb', qs.stringify(values), { centerId }).then((res) => {
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
  };

  const removeItemFromCart = (product_sup) => {
    var values = {};
    if(product_sup.envp) {
      values = {
        session: cookies.get(centerCookieIds[centerId].cookieName),
        pdf:cartData.uid_port[0],
        uid:product_sup.uid,
      };
    }else {
      values = {
        session: cookies.get(centerCookieIds[centerId].cookieName),
        pdf:"a99",
        uid:product_sup.uid,
      };
    }
    
    return API.post('/uefmfufgspndbs', qs.stringify(values), { centerId }).then((res) => {
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
        if(product_sup.envp) {
          removeProductAsCoffret(product_sup.uid);
        }
        
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
  };

  const addItemToCart = async (id, qty, recipient) => {
    const itemData = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      quantity: qty,
      variant: id,
      recipient,
      envp: null,
      datexp: null,
      mainpropre: false,
      email: null,
      gift: false
    };
    return API.post('/ubeeupdbs', qs.stringify(itemData), {
      centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });
  };
  
  const changeGiftStatus = (uid, val_gift) => {
    /*const values = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      uid,
      gift:val_gift
    };
    return API.post('/uefmfufgspndbs', qs.stringify(values), { centerId }).then((res) => {
      if (res.data.status === 1) {
        setCart(res.data.responseData.cartContents);
      }else {
        if(res.data.status > 1) {
          if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }
      }
    });*/
  };

  const upgradeItemInCart = async (oldProduct, newProduct) => {
    await removeItemFromCart(oldProduct.uid);
    await addItemToCart(newProduct.id, oldProduct.quantity, newProduct.recipient);
  };

  return (
    <Layout location={location}>
      <SEO title="Mon panier" />
      <div className="cart--wrapper container">
        {isValidCenter ? (
          <>
            {!cartData?.Products.length ? (
              <EmptyCart selectedCenter={selectedCenter} />
            ) : (
              <NonEmptyCart
                cartData={cartData}
                selectedCenter={selectedCenter}
                removeItemFromCart={removeItemFromCart}
                changeQuantity={changeQuantity}
                setCart={setCart}
                addItemToCart={addItemToCart}
                upgradeItemInCart={upgradeItemInCart}
                setProductAsGift={setProductAsGift}
                removeProductAsGift={removeProductAsGift}
                giftProducts={giftProducts}
                removeProductAsCoffret={removeProductAsCoffret}
                coffretProducts={coffretProducts}
                changeGiftStatus={changeGiftStatus}
                setCodeCadeau={setCodeCadeau}
                removeCodeCadeau={removeCodeCadeau}
                codesCadeau={codesCadeau}
              />
            )}
          </>
        ) : (
          <SelectYourCenter centers={centers} location={location} />
        )}
      </div>
    </Layout>
  );
};

export default Cart;
