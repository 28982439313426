import React from 'react';
import { string } from 'prop-types';

const Pin = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 13 20">
    <g fillRule="nonzero" fill={props.fill}>
      <path d="M6.5 0C2.916 0 0 2.938 0 6.55c0 3.47 5.907 12.863 6.159 13.261a.404.404 0 00.682 0C7.093 19.413 13 10.021 13 6.55 13 2.938 10.084 0 6.5 0zm0 18.822a98.805 98.805 0 01-2.694-4.678C1.846 10.494.81 7.87.81 6.55.81 3.388 3.362.816 6.5.816c3.138 0 5.69 2.572 5.69 5.734 0 1.319-1.036 3.945-2.996 7.594A98.87 98.87 0 016.5 18.822z" />
      <path d="M6.5 3.53a2.951 2.951 0 00-2.955 2.94A2.951 2.951 0 006.5 9.413c1.63 0 2.955-1.32 2.955-2.941A2.951 2.951 0 006.5 3.529zm0 5.086c-1.2 0-2.177-.963-2.177-2.145 0-1.183.977-2.146 2.177-2.146 1.2 0 2.177.963 2.177 2.146 0 1.182-.977 2.145-2.177 2.145z" />
    </g>
  </svg>
);

Pin.defaultProps = {
  fill: '#FFFFFF',
};

Pin.propTypes = {
  fill: string,
};

export default Pin;
